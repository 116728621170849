<template>
  <div
    class="customerManagement"
    v-loading.fullscreen.lock="loading"
    element-loading-text="拼命加载中"
    element-loading-background="rgba(255, 255, 255, 0.5)"
  >
    <div style="float: left">
        <!-- <el-select clearable v-model="pageInfo.countryId" placeholder="全部国家" class="el_select" @change="find">
          <el-option
            v-for="item in countryList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select> -->
      <ShopsAndSites
        v-if="shopList.length != 0"
        placeholder="请选择店铺"
        :clearable="false"
        style="margin-right: 10px"
        :list="shopList"
        @getShopId="getID"
      ></ShopsAndSites>
      <el-select
        style="width: 115px; margin: 5px"
        v-model="pageInfo.status"
        placeholder="规则状态"
        @change="find"
      >
      <el-option
      v-for="item in ruleList"
      :key="item.value"
      :label="item.label"
      :value="item.value"
      >
                  </el-option>
      </el-select>
      <TimeQuantum
        style="margin-right: 10px"
        @selectFinish="topTime"
        section="近7天"
        @sectionFinish="sectionSure"
      ></TimeQuantum>
    </div>
    <div style="width: 300px; float: left; margin-top: 5px">
      <el-input
        placeholder="请输入规则名称"
        suffix-icon="el-icon-search"
        v-model="pageInfo.name"
        @keyup.enter.native = "find"
      >
      </el-input>
    </div>
    <el-button type="primary" @click="find" style="margin: 5px"
      >搜索</el-button
    >
    <br />
    <div style="width: 300px; margin-top: 22px">
      <el-button type="primary" @click="addRule">添加规则</el-button>
      <el-dropdown
        trigger="click"
        @command="myhandleCommand"
        style="margin-left: 8px; margin-top: -1px"
      >
        <el-button class="el-dropdown-link" type="primary">
          批量操作<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="allopen">批量开启</el-dropdown-item>
          <el-dropdown-item command="allband">批量禁用</el-dropdown-item>
          <el-dropdown-item command="alldelate">批量删除</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- 表格 -->
    <el-table
      :data="tableData"
      border
      cell-style="text-align:center"
      header-cell-style="text-align:center;background:#EBEEF5"
      style="width: 100%; margin-top: 40px"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        width="55"
        align="center"
        fixed
      ></el-table-column>
      <el-table-column prop="ruleName" label="规则名称" width="width">
        <template slot-scope="scope">
          <el-link type="primary" @click="getDetail_left(scope.row.id)">{{
            scope.row.ruleName
          }}</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="marketPlaceName" label="国家" width="width">
      </el-table-column>
      <el-table-column prop="shopName" label="店铺" width="width">
      </el-table-column>
      <el-table-column prop="status" label="状态" width="width">
        <template slot-scope="scoped">
          <div
            style="
              display: flex;
              justify-content: space-around;
              align-items: center;
              padding: 0 20px;
            "
          >
            <el-switch
              style="display: block"
              v-model="scoped.row.status"
              active-color="#13ce66"
              inactive-color="#909399"
              :active-value="1"
              :inactive-value="0"
              @change="changeStatus(scoped.row.id, scoped.row.status)"
            >
            </el-switch>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="总请求" width="width" prop="totalRequestNum">
        <template slot="header">
          <span>总请求</span>
          <el-tooltip effect="dark" placement="top">
            <div
              slot="content"
              style="line-height: 20px; min-width: 150px; max-width: 350px"
            >
              <p>到达规则设置的请求时间时，总请求数才会更新。</p>
            </div>
            <span class="el-icon-question"></span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="requestSuccessNum" label="请求成功" width="width">
      </el-table-column>
      <el-table-column prop="requestFailNum" label="请求失败" width="width">
      </el-table-column>
      <el-table-column prop="creatBy" label="创建人" width="width">
      </el-table-column>
      <el-table-column prop="updateBy" label="操作人" width="width">
      </el-table-column>
      <el-table-column prop="updateTime" label="更新时间" width="width">
      </el-table-column>
      <el-table-column prop="prop" label="操作" width="width">
        <template slot-scope="scope">
          <el-button
            type="text"
            style="font-size: 13px; margin-right: 25px"
            @click="getDetail_right(scope.row.id)"
            >详情</el-button
          >
          <el-dropdown trigger="click" @command="handleCommand">
            <el-button
              class="el-dropdown-link"
              type="text"
              @click="getNewid(scope.row.id)"
            >
              操作<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="update">编辑</el-dropdown-item>
              <el-dropdown-item command="delate">删除</el-dropdown-item>
              <el-dropdown-item command="again">复制新增</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加规则 -->
    <el-dialog
      ref="formInline"
      :title="title"
      :visible.sync="addrule"
      width="1550px"
      :before-close="updateDialogClose"
      :close-on-click-modal="false"
    >
      <hr />
      <div class="rule_content">
        <el-form
          ref="formInline"
          :inline="true"
          :model="formInline"
          class="demo-form-inline"
          label-position="right"
          label-width="80px"
        >
          <el-form-item
            label="规则名称"
            prop="ruleName"
            :rules="[
              { required: true, message: '请输入标题', trigger: 'blur' },
            ]"
          >
            <el-input
              style="width: 550px"
              v-model="formInline.ruleName"
              placeholder="请输入，不超过200个字符"
            ></el-input>
          </el-form-item>
          <br />
          <el-form-item label="状态">
            <el-radio v-model="formInline.status" :label="1">开启</el-radio>
            <el-radio v-model="formInline.status" :label="0">关闭</el-radio></el-form-item><br />
          <el-form-item
            label="店铺"
            prop="shopname"
          >
            <ShopsAndSites
              placeholder="请选择店铺"
              :clearable="false"
              style="margin-right: 10px"
              :list="shopList"
              @getShopId="getAddId"
            ></ShopsAndSites> </el-form-item ><br />
          <el-form-item label="发送对象">
            <el-radio-group v-model="formInline.reqSelectType">
              <el-radio :label="0">全部产品订单</el-radio>
              <el-radio :label="1">购买指定ASIN的订单</el-radio>
              <el-radio :label="2">排除购买指定ASIN的订单</el-radio>
              <el-radio :label="3">指定订单</el-radio> </el-radio-group
            ><br />
            <!-- <div
              style="padding: 12px; background: #f5f7fa; max-width: 600px"
              v-if="this.formInline.reqSelectType == 0"
            >
              <p>已选店铺的所有商品</p>
            </div>
            <div
              style="padding: 12px; background: #f5f7fa; max-width: 600px"
              v-if="this.formInline.reqSelectType == 1"
            >
              <el-button @click="stockPageInit">选择商品</el-button>
              <el-button @click="inputAsin">输入ASIN</el-button><br />
              <p>已选ASIN：</p>
              <div class="asinList">
                <div>
                  <span>B07BRQ7TK8</span>
                  <i class="el-icon-close"></i>
                </div>
              </div>
            </div>
            <div
              style="padding: 12px; background: #f5f7fa; max-width: 600px"
              v-if="this.formInline.reqSelectType == 3"
            >
              <el-button @click="chooseOrdersPage">选择订单号</el-button>
              <el-button @click="goodsBox = true">添加订单号</el-button>
              <el-button @click="inputAsin">批量导入</el-button><br />
              <p>已选订单号：</p>
              <div class="asinList">
                <div>
                  <span>B07BRQ7TK8</span>
                  <i class="el-icon-close"></i>
                </div>
              </div>
            </div>  -->
            <div style="padding: 12px; background: #f5f7fa; max-width: 600px">
              <div v-if="this.formInline.reqSelectType =='3'">
                <el-button @click="chooseOrdersPages">选择订单号</el-button>
                <el-button @click="inputwriteOrder">输入订单号</el-button>
                <el-button>批量导入</el-button>
              </div>
              <el-button v-if="this.formInline.reqSelectType=='1'||this.formInline.reqSelectType=='2'" @click="stockPageInit">选择商品</el-button>
              <el-button v-if="this.formInline.reqSelectType=='1'||this.formInline.reqSelectType=='2'" @click="inputAsin">输入ASIN</el-button>
              <p v-if="this.formInline.reqSelectType!='0'">已选
                <span v-if="this.formInline.reqSelectType=='3'">订单号</span>
                <span v-if="this.formInline.reqSelectType=='1'||this.formInline.reqSelectType=='2'">ASIN：</span>
              </p>
              <div v-if="this.formInline.reqSelectType=='1'||this.formInline.reqSelectType=='2'" class="asinList">
                <div 
                v-for="(item,index) in twoInfo.asins"
                :key="item"
                >
                  <span>{{item}}</span>
                  <i @click="twoInfo.asins.splice(index,1)" class="el-icon-close"></i>
                </div>
              </div>
              <div v-if="this.formInline.reqSelectType=='3'" class="asinList">
                <div 
                v-for="(item,index) in twoInfo.orders"
                :key="item"
                >
                  <span>{{item}}</span>
                  <i @click="twoInfo.orders.splice(index,1)" class="el-icon-close"></i>
                </div>
              </div>
              <p v-if="this.formInline.reqSelectType=='0'">已选择商店所有商品</p>
            </div>
            </el-form-item><br />
          <el-form-item
            prop="orderEndTime"
            label="订单范围"
            :rules="[
              { required: true, message: '请选择时间', trigger: 'blur' },
            ]"
          >
            <el-radio v-model="formInline.range" :label="1"
              >订购时间在
              <el-date-picker
                :disabled="this.formInline.range == 1 ? false : true"
                style="width: 250px"
                v-model="formInline.orderEndTime"
                type="date"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd "
                placeholder="选择日期"
              >
              </el-date-picker
              >之后的订单 </el-radio
            ><br />
            <el-radio v-model="formInline.range" :label="0"
              >订购时间在
              <el-date-picker
                :disabled="this.formInline.range == 0 ? false : true"
                style="width: 250px; margin-top: 24px"
                v-model="formInline.value1"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker
              >之间的订单
            </el-radio> </el-form-item
          ><br />
          <el-form-item
            label=" 请求时间"
            prop="requestTimeType"
            :rules="[
              { required: true, message: '请选择时间', trigger: 'blur' },
            ]"
          >
            <el-select v-model="formInline.requestTimeType" style="width: 75px">
              <el-option
                v-for="item in requestTimeTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>

            <el-select
              v-model="formInline.requestDayMin"
              style="width: 100px; margin-left: 6px"
            >
              <el-option
                v-for="item in 30"
                :key="item"
                :label="'第' + item + '天'"
                :value="item"
              >
              </el-option>
            </el-select>
            --
            <el-select v-model="formInline.requestDayMax" style="width: 100px">
              <el-option
                v-for="item in 30"
                :key="item"
                :label="'第' + item + '天'"
                :value="item"
              >
              </el-option>
            </el-select>
            <span style="font-size: 12px">，每天 </span>
            <el-time-select
              style="width: 100px"
              placeholder="起始时间"
              v-model="formInline.requestTimeMin"
              :picker-options="{
                start: '00:00',
                step: '00:30',
                end: '23:30',
              }"
            >
            </el-time-select
            >--
            <el-time-select
              style="width: 100px"
              placeholder="结束时间"
              v-model="formInline.requestTimeMax"
              :picker-options="{
                start: '00:00',
                step: '00:30',
                end: '23:30',
                minTime: formInline.requestTimeMin,
              }"
            >
            </el-time-select>
            <span style="font-size: 12px">发送请求</span> </el-form-item
          ><br />

          <el-form-item label="设定规则" style="width: 900px">
            <div style="width: 790px; line-height: 55px; margin-top: -13px">
              <el-checkbox
                v-model="formInline.excludeReturn"
                :value="checked"
                :true-label="1"
                :false-label="0"
                >排除退货的订单</el-checkbox
              >
              <el-checkbox
                :value="checked"
                :true-label="1"
                :false-label="0"
                v-model="formInline.excludeRefund"
                style="margin-left: 110px"
                >排除退款的订单</el-checkbox
              >

              <el-checkbox
                :value="checked"
                :true-label="1"
                :false-label="0"
                v-model="formInline.excludeBlacklist"
                >排除已加入黑名单的买家 </el-checkbox
              ><br />
              <el-checkbox
                :value="checked"
                :true-label="1"
                :false-label="0"
                v-model="formInline.excludeSdiscount"
                >排除运费有折扣的订单</el-checkbox
              >
              <el-checkbox
                :value="checked"
                :true-label="1"
                :false-label="0"
                v-model="formInline.excludeSurveyOrder"
                style="margin-left: 68px"
                >排除测评订单</el-checkbox
              ><br />
              <el-checkbox
                :value="checked"
                :true-label="1"
                :false-label="0"
                v-model="formInline.excludeBadReviewBuyer"
                >"排除 留过 review 中/差评的买家</el-checkbox
              >
              <el-checkbox
                :value="checked"
                :true-label="1"
                :false-label="0"
                v-model="formInline.excludePdiscount"
                >排除商品有折扣的订单</el-checkbox
              ><br />
              <el-checkbox
                :value="checked"
                :true-label="1"
                :false-label="0"
                v-model="formInline.excludeSentCareMail"
                >排除已发送过关怀邮件的订单</el-checkbox
              >
              <el-checkbox
                :value="checked"
                :true-label="1"
                :false-label="0"
                disabled
                checked
                style="margin-right: 320px; margin-left: 26px"
                >订单类型:
                <el-select
                  v-model="formInline.orderType"
                  placeholder="请选择"
                  style="width: 100px"
                >
                  <el-option
                    v-for="item in orderTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-button type="text">
                  <el-tooltip effect="dark" placement="top-end">
                    <div
                      slot="content"
                      style="
                        line-height: 20px;
                        min-width: 150px;
                        max-width: 450px;
                      "
                    >
                      <p>
                        因FBM的订单暂无法获取到货时间，则FBM的订单不支持通过订单到货时间进行请求
                      </p>
                    </div>
                    <span class="el-icon-question"></span>
                  </el-tooltip>
                </el-button>
              </el-checkbox>
              <el-checkbox
                :value="checked"
                :true-label="1"
                :false-label="0"
                v-model="myLine.status"
              >
              </el-checkbox
              >排除任务开启前<el-input-number
                :disabled="
                  this.myLine.status == 1 ? false : true
                "
                v-model="formInline.excludeEmailContactPeriod"
                @change="numChange"
                :min="1"
                :max="30"
              ></el-input-number
              >天内有过邮件往来的买家 （可填写的天数范围1-30天）<br />
              <el-checkbox
                :value="checked"
                :true-label="1"
                :false-label="0"
                v-model="formInline.excludeAmount"
              >
              </el-checkbox>
              针对订单总金额在<el-input
                v-model="formInline.excludeAmountMin"
                style="width: 135px; margin-left: 5px"
                :disabled="this.formInline.excludeAmount == 1 ? false : true"
              ></el-input>
              -
              <el-input
                :disabled="this.formInline.excludeAmount == 1 ? false : true"
                v-model="formInline.excludeAmountMax"
                style="width: 135px"
              ></el-input
              >之间的订单
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class="beizhu">
        <span>注:1.系统会默认过滤已发送过请求评论的订单</span><br />
        <span>2.系统会自动筛选已完成的订单</span><br />
        <span
          >3.针对已在亚马逊后台或通过其他方式请求的订单，我们暂无立马排除，需要通过请求后才可标识为“不可重复请求”</span
        ><br />
        <span>4.同一个订单请求任务仅存在一个开启状态下的规则里</span>
      </div>
      <div class="footer">
        <el-button @click="addrule = false">取 消</el-button>
        <el-button @click="preview">任务预览</el-button>
        <el-button type="primary" @click="sure" v-if="this.title == '新增规则'"
          >确 定</el-button
        >
        <el-button
          type="primary"
          @click="updatesure"
          v-if="this.title == '编辑规则'"
          >确 定</el-button
        >
        <el-button type="primary" @click="sure" v-if="this.title == '编辑 规则'"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 任务预览 -->
    <el-dialog
      title="任务预览"
      :visible.sync="previewBox"
      width="640px"
      :before-close="updateDialogClose"
      :close-on-click-modal="false"
      append-to-body
    >
      <hr />
      <h3>{{ this.formInline.ruleName }}</h3>
      <el-table
        :data="previewTable"
        border
        cell-style="text-align:center"
        header-cell-style="text-align:center;background:#EBEEF5"
        style="width: 100%; margin-top: 40px"
      >
        <el-table-column prop="ruleName" label="订单号" width="width">
          <template slot-scope="scope">
            <el-link type="primary" @click="orderNumber(scope.row.id)">{{
              scope.row.orderNumber
            }}</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="marketPlaceName" label="店铺" width="width">
        </el-table-column>
      </el-table>
      <div slot="footer">
        <el-button @click="previewBox = false">关 闭</el-button>
      </div>
    </el-dialog>
    <!-- 详情 左 -->
    <el-dialog
      title="请求规则"
      :visible.sync="detailrule_left"
      width="500px"
      :before-close="updateDialogClose"
      :close-on-click-modal="false"
    >
      <hr />
      <div class="per" style="margin-top: 15px">
        <el-form
          v-loading="dialogLoading"
          :model="leftList"
          label-width="120px"
          style="padding: 0 10px"
        >
          <el-form-item label="规则名称"
            ><span class="oo">{{ leftList.ruleName }}</span>
          </el-form-item>
          <el-form-item label="规则状态" prop="phone"
            ><span class="oo">{{ leftList.status == 0 ? "开启" : "关闭" }}</span>
          </el-form-item>
          <el-form-item label="店铺">
            <span class="oo">{{ leftList.shopName }}</span>
          </el-form-item>
          <el-form-item label="发送对象"
            ><span class="oo">{{ leftList.reqSelectType == 0 ? "店铺全部买家" : "" }}</span></el-form-item
          >
          <el-form-item label="订单范围">
            <span></span>
          </el-form-item>
          <el-form-item label="请求时间">
            <span></span>
          </el-form-item>
          <el-form-item label="设定规则">
            <span class="oo">订单类型：{{ leftList.orderType == 0 ? "FBA": leftList.orderType == 1? "FBM": "全部" }}</span><br />
            <span class="oo" v-if="leftList.excludeReturn != 0">排除退货的订单<br/></span>
            <span class="oo" v-if="leftList.excludeRefund != 0">排除退款的订单<br/></span>
            <span class="oo" v-if="leftList.excludeBlacklist != 0">排除已加入黑名单的买家<br/></span>
            <span class="oo" v-if="leftList.excludeSdiscount != 0">排除运费有折扣的订单<br/></span>
            <span class="oo" v-if="leftList.excludeSurveyOrder != 0">排除测评订单<br /></span>
            <span class="oo" v-if="leftList.excludeBadReviewBuyer != 0">排除留过 review 中/差评的买家<br/></span>
            <span class="oo" v-if="leftList.excludePdiscount != 0">排除商品有折扣的订单<br/></span>
            <span class="oo" v-if="leftList.excludeEmailContactPeriod != 0">排除任务开启前{{ leftList.excludeEmailContactPeriod }}天内有过邮件往来的买家<br/></span>
            <span class="oo" v-if="leftList.excludeSentCareMail != 0">排除已发送过关怀邮件的订单<br/></span>
            <span class="oo" v-if="leftList.excludeAmount != 0">针对订单总金额在 {{ leftList.excludeAmountMin }}至{{ leftList.excludeAmountMax }} 的订单<br
            /></span>
          </el-form-item>
        </el-form>
      </div>
      <div class="information">
        <span>1.系统会默认过滤已发送过请求评论的订单</span><br />
        <span>2.系统会自动筛选已完成的订单</span><br />
        <span
          >3.针对已在亚马逊后台或通过其他方式请求的订单，我们暂无立马排除，需要通过请求后才可标识为“不可重复请求”</span
        ><br />
        <span>4.同一个订单请求任务仅存在一个开启状态下的规则里</span>
      </div>
      <div slot="footer">
        <el-button @click="detailrule_left = false">关 闭</el-button>
      </div>
    </el-dialog>
    <!-- 详情 右 -->
    <el-dialog
      title="任务预览"
      :visible.sync="detailrule_right"
      width="640px"
      :before-close="updateDialogClose"
      :close-on-click-modal="false"
      
    >
      <hr />
      <h3>{{ this.formInline.ruleName }}</h3>
      <el-table
        v-loading = "rightloading"
        :data="right.tableData"
        border
        cell-style="text-align:center"
        header-cell-style="text-align:center;background:#EBEEF5"
        style="width: 100%; margin-top: 40px"
      >
        <el-table-column prop="ruleName" label="订单号" width="width">
          <template slot-scope="scope">
            <el-link type="primary" @click="orderNumber(scope.row.id)">{{
              scope.row.amazonOrderNumber
            }}</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="shopName" label="店铺" width="width">
        </el-table-column>
      </el-table>
        <div style="text-align: center; margin-top: 10px">
          <el-pagination
            @current-change="rightChange"
            :current-page="right.current"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="right.total"
          >
          </el-pagination>
        </div>
      <div slot="footer">
        <el-button @click="detailrule_right = false">关 闭</el-button>
      </div>
    </el-dialog>
    <!-- 选择商品 -->
    <el-dialog
      title="选择商品"
      :visible.sync="goodsBox"
      width="1100px"
      append-to-body
      :before-close="goodsBoxClose"
      :destroy-on-close="true"
    >
      <div v-loading="dialogLoading">
        <el-select
          v-model="pageInfo.status"
          clearable
          style="width：150px;margin-right:10px"
          placeholder="请选择商品状态"
          @change="find"
        >
          <el-option
            :label="item.value"
            :value="item.id"
            v-for="item in stockpage.productStatusList"
            :key="item.id"
          ></el-option>
        </el-select>
        <el-input
          style="width: 300px; margin-right: 10px"
          placeholder="请输入"
          v-model="stockpage.keyword"
          class="input-with-select"
          @keyup.enter.native="find"
        >
          <el-select
            clearable
            style="width: 130px"
            v-model="select"
            slot="prepend"
            placeholder="选择输入类型"
          >
            <el-option
              :label="item.value"
              :value="item.id"
              v-for="item in stockpage.searchList"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-input>
        <el-button type="primary" icon="el-icon-search" @click="stockfind"
          >查询</el-button
        >
        <el-table
          ref="goods"
          :data="stockpage.tableData"
          @selection-change="selectStock"
          style="width: 100%; margin-top: 10px"
          :cell-style="{
            fontSize: '13px',
            padding: '7px 0',
            textAlign: 'center',
          }"
          :header-cell-style="{
            fontSize: '13px',
            padding: '5px',
            marginTop: '10px',
            background: '#EBEEF5',
            textAlign: 'center',
          }"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="prop" label="商品">
            <template slot-scope="scope">
              <GoodsImage
                :marketPlaceImageUrl="scope.row.marketPlaceImageUrl"
                :label="scope.row.label"
                :parentASIN="scope.row.parentAsin"
                :imageUrl="scope.row.imageUrl"
                :asin="scope.row.asin"
                :title="scope.row.title"
                :commoditySku="scope.row.commoditySku"
              ></GoodsImage>
            </template>
          </el-table-column>
          <el-table-column prop="asin" label="ASIN"> </el-table-column>
          <el-table-column prop="statusName" label="状态"> </el-table-column>
        </el-table>
        <div style="text-align: center; margin-top: 10px">
          <el-pagination
            @current-change="stockCurrentChange"
            :current-page="stockpage.current"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="stockpage.total"
          >
          </el-pagination>
        </div>
      </div>

      <div slot="footer">
        <el-button @click="goodsBoxClose">取 消</el-button>
        <el-button type="primary" @click="addGoods">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 选择订单号 -->
    <el-dialog
      title="选择订单号"
      :visible.sync="orderNumberBox"
      width="1100px"
      :before-close="updateDialogClose"
      :close-on-click-modal="false"
      append-to-body
    >
      <hr />
      <div v-loading="orderdialogLoading">
        <!-- <el-input
          style="width: 300px; margin-right: 10px"
          placeholder="请输入"
          v-model="stockpage.keyword"
          class="input-with-select"
          @keyup.enter.native="find"
        >
          <el-select
            clearable
            style="width: 130px"
            v-model="select"
            slot="prepend"
            placeholder="选择输入类型"
          >
            <el-option
              :label="item.value"
              :value="item.id"
              v-for="item in stockpage.searchList"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-input> -->
        <el-input placeholder="请输入内容" v-model="input3" class="input-with-select">
          <el-select v-model="select" slot="prepend" placeholder="请选择">
          </el-select>
          <el-button slot="append" icon="el-icon-search"></el-button>
        </el-input>
        <el-table
          :data="orderpage.tableData"
          @selection-change="selectOrder"
          style="width: 100%; margin-top: 10px"
          :cell-style="{
            fontSize: '13px',
            padding: '7px 0',
            textAlign: 'left',
          }"
          :header-cell-style="{
            fontSize: '13px',
            padding: '5px',
            marginTop: '10px',
            background: '#EBEEF5',
            textAlign: 'left',
          }"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="amazonOrderNumber" label="">
            <template slot-scope="scope">
              <el-link type="primary" @click="OrderNumber(scope.row.id)">{{scope.row.amazonOrderNumber}}({{ scope.row.quantityOrderedAll }})</el-link>
            </template>
          </el-table-column>
        </el-table>
        <div style="text-align: center; margin-top: 10px">
          <el-pagination
            @current-change="orderCurrentChange"
            :current-page="orderpage.current"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="orderpage.total"
          >
          </el-pagination>
        </div>
      </div>
      <div slot="footer">
        <el-button @click="detailrule_left = false">关 闭</el-button>
        <el-button type="primary" @click="addOrders">确 定</el-button>

      </div>
    </el-dialog>
    <!-- 输入ASIN -->
    <el-dialog
      title="添加ASIN"
      :visible.sync="inputAsinBox"
      width="600px"
      :before-close="colseasinBox"
      :close-on-click-modal="false"
      append-to-body
    >
      <hr />
        <div>
          <p>共输入{{inputNum}}个asin <span v-if="errorNum>0">;含<span style="color:red">{{errorNum}}</span>个错误格式</span></p><br>
          <el-input @input="enterASIN" @keydown.enter.native="uu" v-model="enterContent" type="textarea" rows="10" placeholder="输入ASIN按回车键分隔请勿输入其他符号及空格，两种方式添加的ASIN最多1000个"></el-input>
          <div>
            <ul v-if="errorList.length>0">
              <li style="color:red;padding:3px" v-for="(item,index) in errorList" :key="index">{{item}}</li>
            </ul>
          </div>
        </div>
      <div slot="footer">
        <el-button @click="colseasinBox">关 闭</el-button>
        <el-button type="primary" @click="addWriteAsin">确 定</el-button>

      </div>
    </el-dialog>
    <!-- 输入订单号 -->
      <el-dialog
        title="输入订单号"
        :visible.sync="writeOrder"
        width="500px"
        append-to-body
        :before-close="writeOrderClose">
        <div>
          <p>共输入{{inputNum}}个订单号</p><br>
          <el-input @input="enterOrder" v-model="enterContent" type="textarea" rows="10" placeholder="输入订单号按回车键分隔请勿输入其他符号及空格，两种方式添加的ASIN最多1000个"></el-input>
          <div>
            <ul v-if="errorList.length>0">
              <li style="color:red;padding:3px" v-for="(item,index) in errorList" :key="index">{{item}}</li>
            </ul>
          </div>
        </div>
        <div slot="footer">
          <el-button @click="writeOrderClose">取 消</el-button>
          <el-button type="primary" @click="addWriteOrder" :loading='dialogLoading'>确 定</el-button>
        </div>
      </el-dialog>
    <!-- 分页 -->
    <div
      class="block"
      style="textalign: center; margintop: 20px; paddingright: 20px"
    >
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.current"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import ShopsAndSites from "@/components/ShopsAndSites";
import {
  pageInit, // 查询初始化
  listPage,
} from "@/api/InventoryManage/stockControl.js";
import TimeQuantum from "@/components/TimeQuantum";
// import { getPageInit } from "@/api/Mail/mailRules.js";
import { pageInitList, }from '@/api/Mail/customerList.js'
import GoodsImage from "@/components/GoodsImage";
import {
  addRule,
  getlistPage,
  getdetail,
  deleteRule,
  update,
  updatestatus,
  chooseOrdersPage, //选择订单号
  checkOrderNum, //检查订单号
  checkAsin, //检查asin
  batchOperation,
  getPreviewList,
} from "@/api/Mail/requestRule.js";
export default {
  components: {
    TimeQuantum,
    ShopsAndSites,
    GoodsImage,
  },
  data() {
    return {
      detailrule_right:false,
      rightloading:false,
      inputNum:0,
      enterContent:'',
      writeOrder:false,
      errorList:[],
      sendType:"0",
      asinNumber:'',
      inputAsinBox:false,
      orderNumberBox:false,
      orderdialogLoading:false,
      dialogLoading: false,
      shopId: "",
      marketPlaceId: "",
      shopList: [],
      previewTable: [],
      goodsBox: false,
      previewBox: false,
      daystart: "",
      title: "",
      dayend: "",
      startTime: "",
      endTime: "",
      ruleList: [
        {
          value: "0",
          label: "禁用",
        },
        {
          value: "1",
          label: "开启",
        }
      ],
      orderTypeList: [
        {
          value: "0",
          label: "全部",
        },
        {
          value: "1",
          label: "FBA",
        },
        {
          value: "2",
          label: "FBM",
        },
      ],
      requestTimeTypeList: [
        {
          value: "0",
          label: "订购",
        },
        {
          value: "1",
          label: "发货",
        },
        {
          value: "2",
          label: "到货",
        },
      ],
      num: 1,
      myLine:{
        status:'',
      },
      detailrule_left: false,
      addrule: false,
      loading: false,
      tableData: [],
      pageInfo: {
        pageSize: 10,
        current: 1,
        countryId: "",
        shopId:'',
        marketPlaceId:'',
        status:'',
        name:'',
        beginTime:'',
        endTime:''
      },
      total: 0,
      formInline: {
        orderType: "",
        shopname: "",
        status: "",
        range: "",
        ruleName: "",
        excludeReturn: "",
        excludeRefund: "",
        excludeBlacklist: "",
        excludeSdiscount: "",
        excludeSurveyOrder: "",
        excludeBadReviewBuyer: "",
        excludePdiscount: "",
        excludeSentCareMail: "",
        excludeAmountMin: "",
        excludeAmountMax: "",
        orderEndTime: "",
        value1: "",
        requestTimeType: "",
        reqSelectType: "",
        marketPlaceId: "",
        shopId: "",
        requestTimeTrigger: "",
        requestTimeInterval: "",
        requestTimeMax:"",
        requestTimeMin:"",
        requestDayMax:'',
        requestDayMin:'',
        rulesOrders:[],
        excludeEmailContactPeriod:'',
      },
      ruleName: "",
      leftList: {},
      updateid: "",
      multipleSelection: [],
      idList: [],
      twoInfo: {
        mailAccountId: "",
        selectType: "0",
        asins: [],
        orders: [],
      },
      stockpage: {
        current: 1,
        statuse: "",
        productStatusList: [],
        asin: "",
        parent_asin: "",
        searchType: "asin",
        keyword: "",
        tableData: [],
        searchList: [],
        total: "",
        asins: [],
      },
      orderpage:{
        current: 1,
        total: "",
        tableData: [],
        orders: [],
      },
      right:{
        tableData:[],
        total:'',
        current:''
      },
      countryList:[],
    };
  },
  created() {
    this.getData();
    this.getPageInit()
  },
  watch:{
  },
  methods: {
    addcode(){

    },
    //页面初始化
    async getData() {
      this.loading = true;
      let params = {
        countryId:this.pageInfo.countryId,
        shopId:this.pageInfo.shopId,
        marketPlaceId:this.pageInfo.marketPlaceId,
        status:this.pageInfo.status,
        name:this.pageInfo.name,
        beginTime:this.pageInfo.beginTime,
        endTime:this.pageInfo.endTime

      };
      try {
        const res = await getlistPage(params);
        if ((Object.is(res.code), 200)) {
          this.loading = false;
          this.tableData = res.data.values;
          this.total = res.data.pageInfo.total;
        } else {
          this.$message.error(res.message);
          this.loading = false;
        }
      } catch (error) {}
    },
    //查询操作
    find(){
      this.getData();
    },
    getPageInit() {
      this.loading = true;
      pageInitList().then((res) => {
        this.loading = false;
        this.shopList = res.data.data.shopList;
        this.countryList = res.data.data.countryList
      });
    },
    //新增按钮
    addRule() {
      this.addrule = true;
      this.title = "新增规则";
      this.formInline = {
        orderType: "1",
        shopname: "",
        status: 1,
        range: 1,
        ruleName: "",
        excludeReturn: 1,
        excludeRefund: 1,
        excludeBlacklist: "",
        excludeSdiscount: "",
        excludeSurveyOrder: "",
        excludeBadReviewBuyer: "",
        excludePdiscount: "",
        excludeSentCareMail: "",
        excludeAmountMin: "",
        excludeAmountMax: "",
        orderEndTime: "",
        value1: "",
        requestTimeType: "1",
        reqSelectType: 0,
        marketPlaceId: "",
        shopId: "",
        requestTimeTrigger: "",
        requestTimeInterval: "",
        requestTimeMax:"",
        requestTimeMin:"",
        requestDayMax:'',
        requestDayMin:'',
        rulesOrders:[],
      };
    },

    // async sure() {
    //   //   this.formInline.requestTimeInterval.push(this.daystart);
    //   //   this.formInline.requestTimeInterval.push(this.dayend);
    //   //   this.formInline.requestTimeTrigger.push(this.startTime);
    //   //   this.formInline.requestTimeTrigger.push(this.endTime);
    //   this.formInline.requestTimeTrigger = this.startTime + "," + this.endTime;
    //   this.formInline.requestTimeInterval = this.daystart + "," + this.dayend;

    // this.$refs.formInline.validate((valid) => {
    //  if (valid) {
    //   try {
    //     const res = await addRule(this.formInline);
    //     if ((Object.is(res.code), 200)) {
    //       if (res.data.code == 200) {
    //         this.addrule = false;
    //         this.getData();
    //         this.$message.success(res.data.message);
    //       } else {
    //         this.$message.error(res.data.message);
    //       }
    //     } else {
    //       this.$message.error(res.message);
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    //   }else {
    //       console.log("error submit!!");
    //       return false;
    //     }
    //   });
    // },

    //新增确认
    sure() {
      console.log(this.formInline.requestDayMin);
      if(this.formInline.reqSelectType == 1){
      this.formInline.rulesOrders =  this.twoInfo.asins
      }else if(this.formInline.reqSelectType == 2){
      this.formInline.rulesOrders =  this.twoInfo.asins
      }else if(this.formInline.reqSelectType == 3){
      this.formInline.rulesOrders =  this.twoInfo.orders
      }
      this.formInline.shopId = this.shopId,
      this.formInline.marketPlaceId = this.marketPlaceId,
      // this.formInline.requestTimeTrigger = this.startTime + "," + this.endTime;
      // this.formInline.requestTimeInterval = this.daystart + "," + this.dayend;
      this.$refs.formInline.validate((valid) => {
        if (valid) {
          this.loading = true;
          addRule(this.formInline).then((res) => {
            this.loading = false;
            if (res.data.code == 200) {
              this.addrule = false;
              this.getData();
              this.$message.success(res.data.message);
            } else {
              this.$message.error(res.data.message);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //详情 左
    getDetail_left(id) {
      this.loading = true;
      getdetail(id).then((res) => {
        this.detailrule_left = true;
        this.loading = false;
        if (res.data.code == 200) {
          this.leftList = res.data.data;
        } else {
          this.detailrule_left = false;
          this.$message.warning(res.data.message);
        }
      });
    },
    //操作
    getNewid(id){
      this.updateid = id;
    },
    //详情 右
    getDetail_right(id) {
      this.detailrule_right = true;
      this.rightloading = true;
      let data = {
        ruleId:id
      }
      getPreviewList(data).then((res) => {
        this.rightloading = false;
        this.right.tableData = res.data.values
        this.right.total = res.data.pageInfo.total
      });

    },
    //操作
    handleCommand(command) {
      if (command == "update") {
        this.title = "编辑规则";
        getdetail(this.updateid).then((res) => {
          this.loading = false;
          if (res.data.code == 200) {
            this.addrule = true;
            this.formInline = res.data.data;
            console.log(this.formInline);
          } else {
            this.$message.warning(res.data.message);
          }
        });
      }
      if (command == "delate") {
        deleteRule(this.updateid).then((res) => {
          this.loading = false;
          if (res.data.code == 200) {
            this.$message.success(res.data.message);
            this.getData();
          } else {
 
            this.$message.warning(res.data.message);
          }
        });
      }
      if (command == "again") {
        this.title = "编辑 规则";
        getdetail(this.updateid).then((res) => {
          this.loading = false;
          if (res.data.code == 200) {
            this.addrule = true;
            this.formInline = res.data.data;
            console.log(this.formInline);
          } else {
            this.addrule = false;

            this.$message.warning(res.data.message);
          }
        });
      }
    },
    //编辑确认
    updatesure() {
      update(this.formInline).then((res) => {
        this.loading = false;
        if (res.data.code == 200) {
        } else {

          this.$message.warning(res.data.message);
        }
      });
    },
    //修改状态
    changeStatus(id, status) {
      this.loading = true;
      let data = {
        id: id,
        status: status,
      };
      updatestatus(data).then((res) => {
        this.loading = false;
        if (res.data.code == 200) {
          this.$message.success(res.data.message);
          this.getData();
        } else if (res.data.code == 500) {
          this.$message.error(res.data.message);
        }
      });
    },
    //预览
    preview() {
      this.previewBox = true;
      getPreviewList(data).then((res) => {
        this.rightloading = false;
        this.right.tableData = res.data.values
        this.right.total = res.data.pageInfo.total
      });
    },
    //表格多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.idList = [];
      this.multipleSelection.forEach((data) => {
        this.idList.push(data.id);
      });
    },
    //批量操作
    myhandleCommand(command) {
      if (this.multipleSelection.length == 0) {
        this.$message.error("请先勾选需要批量操作对象");
        return false;
      }
      if (command == "allopen") {
        let data = {
          ids:this.idList,
          type: 0
        }
        batchOperation(data).then((res) => {
          this.loading = false;
          if (res.data.code == 200) {
            this.$message.success(res.data.message);
            this.getData()
          } else {
            this.$message.warning(res.data.message);
          }
        });
      }
      if (command == "allband") {
        let data = {
          ids:this.idList,
          type: 1
        }
        batchOperation(data).then((res) => {
          this.loading = false;
          if (res.data.code == 200) {
            this.$message.success(res.data.message);
            this.getData()
          } else {
            this.$message.warning(res.data.message);
          }
        });
      }
      if (command == "alldelate") {
        let data = {
          ids:this.idList,
          type: 2
        }
        batchOperation(data).then((res) => {
          this.loading = false;
          if (res.data.code == 200) {
            this.getData()
            this.$message.success(res.data.message);
          } else {
            this.$message.warning(res.data.message);
          }
        });
      }
    },
    //选择订单号
    chooseOrdersPages() {
      this.orderdialogLoading = true;
      let params = {
        sendType:this.sendType,
        shopId: this.shopId,
        marketPlaceId: this.marketPlaceId,
      }
      chooseOrdersPage(params).then((res) => {
        this.orderdialogLoading = false;
        this.orderNumberBox = true;
        this.orderpage.tableData = res.data.values;
        this.orderpage.total = res.data.pageInfo.total
      });
    },
    orderCurrentChange(val) {
      this.orderpage.current = val;
      this.chooseOrdersPages();
    },

    addOrders(){
      this.twoInfo.orders = this.orderpage.orders.map((item) => item);
      console.log(this.twoInfo.orders);
      
    },

    selectOrder(val){
      val.forEach(item=>{
        if(!this.orderpage.orders.includes(item.amazonOrderNumber)){
          this.orderpage.orders.push(item.amazonOrderNumber)
        }
      })
    },
    //选择商品
    stockPageInit() {
      this.stockpage.asins = this.twoInfo.asins.map((item) => item);
      this.dialogLoading = true;
      pageInit()
        .then((res) => {
          this.dialogLoading = false;
          this.goodsBox = true;
          this.stockpage.productStatusList = res.data.data.productStatusList;
          this.stockpage.searchList = res.data.data.searchList;
        })
        .then((res) => {
          this.stockListPage();
        });
    },
    stockListPage() {
      this.dialogLoading = true;
      listPage({
        shopId: this.shopId,
        marketPlaceId: this.marketPlaceId,
        statuse: this.stockpage.statuse,
        [this.stockpage.searchType]: this.stockpage.keyword,
        current: this.stockpage.current,
      }).then((res) => {
        this.dialogLoading = false;
        this.stockpage.tableData = res.data.values;
        this.stockpage.total = res.data.pageInfo.total;
        this.stockpage.tableData.forEach((item, index) => {
          if (this.stockpage.asins.includes(item.asin)) {
            this.$nextTick(() => {
              this.$refs.goods.toggleRowSelection(
                this.stockpage.tableData[index]
              );
            });
          }
        });
      });
    },
    stockCurrentChange(val) {
      this.stockpage.current = val;
      this.stockListPage();
    },
    stockfind() {
      this.stockpage.current = 1;
      this.stockListPage();
    },
    getAddId(shopId, marketPlaceId) {
      this.shopId = shopId;
      this.marketPlaceId = marketPlaceId;
    },
    getID(shopId, marketPlaceId) {
      this.pageInfo.shopId = shopId;
      this.pageInfo.marketPlaceId = marketPlaceId;
    },
    selectStock(val){
      val.forEach(item=>{
        if(!this.stockpage.asins.includes(item.asin)){
          this.stockpage.asins.push(item.asin)
        }
      })
    },
    addGoods() {
      this.twoInfo.asins = this.stockpage.asins.map((item) => item);
      this.goodsBoxClose();
    },
    goodsBoxClose() {
      this.goodsBox = false;
      this.stockpage = {
        current: 1,
        statuse: "",
        productStatusList: [],
        asin: "",
        parent_asin: "",
        searchType: "asin",
        keyword: "",
        tableData: [],
        searchList: [],
        total: "",
        asins: [],
      };
    },
    //输入asin
    inputAsin(){
      this.inputAsinBox = true
    },
    addWriteAsin(){
      this.dialogLoading=true
      checkAsin({
        marketPlaceId:this.marketPlaceId,
        shopId:this.shopId,
        strAsin:this.enterContent
      }).then(res=>{
        this.dialogLoading=false
        console.log(res);
        if(res.data.code==200){
          this.errorList=[]
          if(this.twoInfo.asins.length>0){
            res.data.data.forEach(item=>{
              if(!this.twoInfo.asins.includes(item)){
                this.twoInfo.asins.push(item)
              }
            })
          }else {
            this.twoInfo.asins=res.data.data
          }
          this.colseasinBox()
        }else {
          this.errorList=res.data.data
        }
      })
    },
    enterASIN(){
      const reg = /^(B|b)[0-9]{1}[0-9]{1}\w{7}$/;
      if(!this.enterContent){
        this.errorNum=0
        this.inputNum=0
        return 
      } 
      const list = this.enterContent.indexOf<0?[this.enterContent]:this.enterContent.split(';')
      let num = 0
      const arr = []
      this.inputNum=list.length
      list.forEach(item => {
        if (!reg.test(item)) num++;
        if(reg.test(item)) arr.push(item)
      });
      this.errorNum=num
      this.successList=arr.join(';')
    },
    //关闭弹框
    colseasinBox(){
      this.inputAsinBox = false
      this.enterContent='',
      this.inputNum = 0
      this.successList = 0
      this.errorNum = 0
      this.errorList = []
    },
    uu(e){
      e.preventDefault();
      this.enterContent+=';'
    },
    //输入订单号
    inputwriteOrder(){
      this.writeOrder = true

    },
    addWriteOrder(){
      this.dialogLoading=true
      checkOrderNum({
        marketPlaceId:this.marketPlaceId,
        shopId:this.shopId,
        strOrder:this.enterContent
      }).then(res=>{
        this.dialogLoading=false
        console.log(res);
        if(res.data.code==200){
          this.errorList=[]
          if(this.twoInfo.orders.length>0){
            res.data.data.forEach(item=>{
              if(!this.twoInfo.orders.includes(item)){
                this.twoInfo.orders.push(item)
              }
            })
          }else {
            this.twoInfo.orders=res.data.data
          }
          this.writeOrderClose()
        }else {
          this.errorList=res.data.data
        }
      })
    },
    writeOrderClose(){
      this.writeOrder = false
      this.enterContent = '',
      this.inputNum = 0
      this.successList = 0
      this.errorNum = 0
      this.errorList = []
    },
        //日期选择
    topTime(val) {
      this.pageInfo.beginTime = val.value[0];
      this.pageInfo.endTime = val.value[1] + " 23:59:59";
    },
  },
};
</script>

<style scoped lang="scss">
.rule_content {
  margin: 0 auto;
  width: 800px;
}
.beizhu {
  margin-left: 442px;
  font-size: 12px;
  color: #aaa;
  line-height: 23px;
}
.footer {
  margin-left: 441px;
  margin-top: 31px;
}
.oo {
  font-size: 12px;
  color: #000;
}
.information {
  line-height: 22px;
  font-size: 12px;
  color: #aaa;
  margin: 0;
}
::v-deep .el-input__icon el-icon-time {
  display: none;
}
</style>