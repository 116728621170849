import request from '@/utils/request.js'
// 请求规则页面

//新增
export function addRule(data) {
    return request({
        url:'/api/requestrules/add',
        method:'post',
        data
    });
}
//查询
export function getlistPage(params) {
    return request({
        url:'/api/requestrules/listPage',
        method:'get',
        params
      
    });
}
//删除
export function deleteRule(id) {
    return request({
      url: "/api/requestrules/delete/" + id,
      method: "delete"
    })
}
//详情
export function getdetail(id) {
    return request({
        url:'/api/requestrules/detail/' + id,
        method:'get',
      
    });
}
//修改确认
export function update(data) {
    return request({
      url: "/api/requestrules/update",
      data,
      method: "put"
    })
  }
  //修改状态
  export function updatestatus(data) {
    return request({
        url:'/api/requestrules/updatestatus',
        method:'post',
        data
    });
}
//批量操作
export function batchOperation(data) {
    return request({
        url:'/api/requestrules/batchOperation',
        method:'post',
        data
    });
}
//选择订单号
export function chooseOrdersPage(params) {
    return request({
        url:'/api/requestrules/chooseOrdersPage',
        method:'get',
        params
      
    });
}
//检查添加订单号
export function checkOrderNum(data) {
    return request({
        url:'/api/requestrules/checkOrderNum',
        method:'post',
        data
    });
}
//检查添加asin
export function checkAsin(data) {
    return request({
        url:'/api/requestrules/checkAsin',
        method:'post',
        data
    });
}
//预览
export function getPreviewList(data) {
    return request({
        url:'/api/requestrules/getPreviewList',
        method:'post',
        data
    });
}